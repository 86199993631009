import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Translator, Translate } from "react-auto-translate";
import { useHistory } from "react-router-dom";

import { setScrollPage } from "./../actions/index";

import AppStore from "../assets/images/icons/appStore_V2.svg";
import GooglePlay from "../assets/images/icons/googlePlay_v2.svg";
import IconYoutube from "../assets/images/icons/youtube.svg";
import IconFacebook from "../assets/images/icons/facebook.svg";
import Logo from "../assets/images/logo-nghiadung.svg";
import Address from "../assets/images/icons/address.svg";
import Address1 from "../assets/images/icons/address1.svg";

import Email from "../assets/images/icons/mail.svg";
import Phone from "../assets/images/icons/phone.svg";

import "../assets/styles/footer/footer_v2.scss";

const listMenu = [
  {
    id: 1,
    name: "menu.history",
    url: "section-history",
  },
  {
    id: 2,
    name: "menu.club",
    url: "section-club",
  },
  {
    id: 3,
    name: "menu.skill",
    url: "section-skill",
  },
  {
    id: 4,
    name: "menu.activity",
    url: "section-activity",
  },
  {
    id: 5,
    name: "menu.library",
    url: "section-library",
  },
  {
    id: 6,
    name: "menu.information",
    url: "section-information",
  },
];

const Footer = ({ listRoute }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const appStore = [
    {
      id: 2,
      image: AppStore,
      // url: "https://apps.apple.com/vn/app/apple-store/id1526775987?l=vi",
    },
    {
      id: 1,
      image: GooglePlay,
      // url: "https://play.google.com/store/apps/details?id=com.nghiadung.karate",
    },
  ];

  const handleRedirect = (id) => {
    history.push("/");
    setTimeout(function () {
      dispatch(setScrollPage(id));
    }, 500);
  };

  const handleRedirectSearch = () => {
    history.push("/search");
  };

  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <footer className="feature-footer">
      <div className="container">
        <div className="footer-container">
          <div className="feature-footer__container">
            <div className="feature-footer__container__left">
              <div className="feature-footer__container__left__image">
                <img src={Logo} alt="nghia-dung" />
              </div>
              <div className="feature-footer__container__left__info">
                <div className="feature-footer__container__left__info__row">
                  <img
                    src={Address1}
                    alt="address"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p> {t("footer.address1")}</p>
                </div>

                <div className="feature-footer__container__left__info__row">
                  <img
                    src={Phone}
                    alt="phone"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <div>
                    <p>
                      {t("footer.name1")}
                      <span>
                        <a href="tel:0942493030">{t("footer.phone1")}</a>
                      </span>
                    </p>
                    <p>
                      {t("footer.name2")}
                      <span>
                        <a href="tel:0942493030">{t("footer.phone2")}</a>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="feature-footer__container__left__info__row">
                  <img
                    src={Email}
                    alt="email"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <a href="mailto: nghiadungkarate.co@gmail.com">
                    <p> {t("footer.email")}</p>
                  </a>
                </div>
                <div className="feature-footer__container__left__info__row">
                  <div className="wrap-icon">
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.youtube.com/channel/UC7F4FBGmnGpl9tC2hLAXIGg"
                    >
                      <img src={IconYoutube} alt="facebook" />
                    </a>
                  </div>

                  <div className="wrap-icon">
                    <a
                      rel="noreferrer noopener"
                      target="_blank"
                      href="https://www.facebook.com/nghiadungkhongthu/"
                    >
                      <img src={IconFacebook} alt="facebook" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-footer__container__right">
              <div className="feature-footer__container__right--content ">
                <h1>
                  {t("footer.titleDownloadAppOne")}
                  <br />
                  {t("footer.titleDownloadAppTwo")}
                </h1>
              </div>
              <div className="feature-footer__container__right--social ">
                {appStore &&
                  appStore.map((store) => {
                    return (
                      <div className="social-item" key={store.id}>
                        <img
                          src={store.image}
                          alt="tai ung dung"
                          width="100%"
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* <hr className='feature-footer--hr' /> */}

          {/* <div
            className="
          // py-3 px-4 
          feature-footer_wrapper"
          >
            <ul className="feature-footer__nav">
              {listMenu &&
                listMenu.map((item) => (
                  <li key={item.id} onClick={() => handleRedirect(item.url)}>
                    <Link to="">{t(item.name)}</Link>
                  </li>
                ))}
              <li onClick={() => handleRedirectSearch('')}>
                <Link to="">{t('menu.search')}</Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div className="row m-auto justify-content-center align-items-center py-3 feature-footer_copyright">
        <p>{t("footer.textCopyRight")}</p>
      </div>
    </footer>
    // </Translator>
  );
};

export default Footer;
