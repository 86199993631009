import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTakenData } from "../utils/service";
import { URL_API_LASTEST } from "../utils/constants";
import { useHistory } from "react-router-dom";
import { Carousel, Typography } from "antd";
import { ReactSVG } from "react-svg";
import moment from "moment";

import { TIME_FORMAT } from "../settings/formatDateTime";

import arrow_right from "./../assets/images/icons/arrow_right.svg";
import backgroundImg from "../assets/images/home-update/backgroupd.png";

import "../assets/styles/block-news/block-news.scss";

const BlockNews = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();

  const [dataBlockNews, setDataBlockNews] = useState({});

  const renderDate = () => {
    if (dataBlockNews?.type === "INFORMATION") {
      return dataBlockNews?.created_at;
    } else {
      return moment(dataBlockNews?.created_at, [
        "YYYY-MM-DD",
        "DD.MM.YYYY",
      ]).format(TIME_FORMAT?.DOT_DATE_FORMAT);
    }
  };

  useEffect(() => {
    getTakenData(URL_API_LASTEST)
      .then((res) => {
        setDataBlockNews(res?.data?.data);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const handleInformationDetails = (item) => {
    history.push(
      `${item?.type === "INFORMATION" ? "/web-news" : "/activity-new"}/${
        item?.id
      }`
    );
  };

  return (
    <div className="blockNews">
      <img src={backgroundImg} alt="background" className="background" />
      <div className="blockNews_header">
        <p className="blockNews_header_text">{t("block-news.header")} </p>
      </div>
      <div className="blockNews_container" key={dataBlockNews?.id}>
        <div className="blockNews_container_image">
          <img
            src={
              process.env.REACT_APP_ENDPOINT +
              (dataBlockNews?.type === "INFORMATION"
                ? dataBlockNews?.thumbnail
                : dataBlockNews?.image)
            }
            alt={dataBlockNews?.title || ""}
          />
        </div>
        <div className="blockNews_container_content">
          <div className="blockNews_container_content_title">
            <p>{dataBlockNews?.title}</p>
          </div>
          <div className="blockNews_container_content_text">
            <Typography.Paragraph
              ellipsis={{
                rows: 7,
                expandable: false,
                symbol: "...",
              }}
            >
              {dataBlockNews?.description}
            </Typography.Paragraph>
          </div>
          <div className="blockNews_container_content_day">
            <hr className="blockNews_container_content_day_hr" />
            {renderDate()}
          </div>
          <div
            className="blockNews_container_content_button"
            onClick={() => handleInformationDetails(dataBlockNews)}
          >
            <p className="blockNews_container_content_button_text">
              {t("block-news.button")}
            </p>
            <ReactSVG src={arrow_right} className="arrow-right" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlockNews;
